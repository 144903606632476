import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../config/api';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Home() {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const navigate = useNavigate();

  const handleCloseAnnouncement = () => {
    setShowAnnouncement(false);
    localStorage.setItem('hasSeenAnnouncement', 'true');
  };
  
  useEffect(() => {
    // 检查是否需要显示公告
    const hasSeenAnnouncement = localStorage.getItem('hasSeenAnnouncement');
    if (!hasSeenAnnouncement) {
      setShowAnnouncement(true);
    }

    const fetchData = async () => {
      setLoading(true);
      setError('');
      try {
        // 获取默认影片列表，不带任何搜索条件
        const response = await axios.get(
          `${API_BASE_URL}/douban/suggestion?path=/tv/modules&need_manual_chart_card=1&for_mobile=1`
        );
        
        if (response.data && response.data.modules) {
          setSections(response.data.modules);
        } else {
          setError('未找到影视资源。');
        }
      } catch (error) {
        console.error('加载数据出错:', error);
        setError('加载数据时发生错误，请稍后再试。');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, []); // 空依赖数组，只在组件挂载时执行一次
  
  
  const handleMovieClick = (movie) => {
    navigate(`/list?keyword=${movie.title}`);
  };
  
  return (
    <div className="home-page">
      <Helmet>
        <title>十分阳光影院-永久免费的福利超清影视站</title>
        <meta name="keywords" content="Netflix,VIP电影,热播电视剧,在线观看,蓝光电影,搞笑视频,美剧,韩剧,超前点播,免费视频,在线视频,4K电影,奈飞,超清电影,电影下载,娱乐资讯" />
        <meta name="description" content="十分阳光影院(91aitv.com)是一家免费全球影视资源在线观看的平台,拥有海量、优质、超清蓝光电影和全球的电视剧,高画质在线动漫。专业全网收集最新,最好看的电视剧、高清电影、经典动漫、综艺娱乐节目,十分阳光影院以丰富的内容、极致的观看体验、便捷的高速播放、24小时多平台无缝应用体验以及快捷!" />
      </Helmet>
      {showAnnouncement && (
        <div className="announcement-overlay">
          <div className="announcement-modal">
            <h2>公告内容</h2>
            <div className="announcement-content">
              <p>◆ 官方公告</p>
              <p>一、记住官网https://91aitv.com</p>
              <p>二、无法使用请切换播放源</p>
              <p>三、关注公众号"十分阳光影院"不迷路</p>
            </div>
            <button onClick={handleCloseAnnouncement} className="announcement-btn">
              好的，我记住啦
            </button>
          </div>
        </div>
      )}


      {loading && <p className="loading">加载中...</p>}
      {error && <p className="error">{error}</p>}

      {sections.map((section, index) => {
          if (['tv_hot', 'show_hot', 'tv_american', 'tv_japanese', 'tv_korean', 'tv_animation'].includes(section.module_name)) {
            return (
              <div>
                <h2 className="section-title">{section.data.subject_collection_boards[0].subject_collection.name}</h2>
                <div key={index} className="search-results">
                  {section.data.subject_collection_boards[0].items.slice(0, 6).map((movie, movieIndex) => (
                    <div 
                      key={movieIndex} 
                      className="movie-item"
                      onClick={() => handleMovieClick(movie)}
                    >
                      <LazyLoadImage
                        src={movie.cover?.url?.replace('m_ratio_poster', 's_ratio_poster')}
                        alt={movie.title}
                        placeholderSrc="/loading.gif"
                        onError={(e) => {
                          e.target.src = '/loading.gif';
                        }}
                      />
                        <h3 className="movie-title">{movie.title}</h3>
                        {/* {movie.rating && movie.rating.value > 0 && (
                          <span className="rating-score">{movie.rating.value}</span>
                        )} */}
                        {/* <div className="movie-meta">{movie.card_subtitle}</div> */}
                      </div>
                  ))}
                </div>
                </div>
            );
          }
          return null;
        })}
        </div>
  );
}

export default Home;